/* eslint-disable prefer-spread */
import { replace } from 'lodash'
import numeral from 'numeral'
import { format, formatDistanceToNow } from 'date-fns'
import * as crypto from 'crypto'
import Swal from 'sweetalert2'

const ENCRYPT_KEY = process.env.ENCRYPT_KEY
const ENCRYPT_IV = process.env.ENCRYPT_IV

const searchArrayByName = (
  keyword?: string,
  data = [],
  objectName?: string,
) => {
  if (keyword) {
    if (objectName) {
      return data.filter(
        (str: { [x: string]: string }) =>
          str[objectName].toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
      )
    } else {
      return data.filter(
        (str: string) => str.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
      )
    }
  } else {
    return data
  }
}

const checkEmailFormat = (_value: string) => {
  const EmailFormat =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return _value ? (_value.match(EmailFormat) ? true : false) : false
}

const fCurrency = (number: unknown) =>
  numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00')

const fMoney = (number: unknown) => {
  if (!number || number === 0) {
    return 0
  }
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00')
}

const fPercent = (number: number) =>
  Number(numeral(number / 100)) === 0
    ? '0%'
    : numeral(number / 100).format('0.0%')

const fNumber = (number: any) => numeral(number).format()

const fShortenNumber = (number: any) =>
  replace(numeral(number).format('0.00a'), '.00', '')

const fData = (number: any) => numeral(number).format('0.0 b')

const fDate = (date: string | number | Date) =>
  format(new Date(date), 'dd MMMM yyyy')

const fDateTime = (date: string | number | Date) =>
  format(new Date(date), 'dd MMM yyyy HH:mm')

const fDateTimeUser = (date: string | number | Date) =>
  format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
const fDateTimeSuffix = (date: string | number | Date) =>
  format(new Date(date), 'dd/MM/yyyy hh:mm p')

const fToNow = (date: string | number | Date) =>
  formatDistanceToNow(new Date(date), { addSuffix: true })

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const haxToUrlBlob = (img: string) => {
  let image = img.replace(/[^A-Fa-f0-9]/g, '')
  let binary = []
  for (let i = 0; i < image.length / 2; i++) {
    let h = image.substring(i * 2, i * 2 + 2)
    binary[i] = parseInt(h, 16)
  }
  let byteArray = new Uint8Array(binary)
  return window.URL.createObjectURL(
    new Blob([byteArray], { type: 'application/octet-stream' }),
  )
}

const cryptoUtil = (text: string) => {
  let algorithm = 'aes-256-cbc'
  let enc_key = Buffer.from(`${ENCRYPT_KEY}`, 'hex')
  let iv = Buffer.from(`${ENCRYPT_IV}`, 'hex')
  const cipher = crypto.createCipheriv(algorithm, enc_key, iv)

  let encrypted = cipher.update(text, 'utf8', 'base64')
  encrypted += cipher.final('base64')
  return encrypted
}

const findMaxValueObjInArray = ({
                                  array,
                                  field,
                                }: {
  array: any
  field: string
}) => {
  return Math.max.apply(
    Math,
    array.map((o: any) => {
      return o[field]
    }),
  )
}

const findMinValueObjInArray = ({
                                  array,
                                  field,
                                }: {
  array: any
  field: string
}) => {
  return Math.min.apply(
    Math,
    array.map((o: any) => {
      return o[field]
    }),
  )
}

const Swal2Alert = ({
                      title,
                      icon,
                      showConfirmButton,
                    }: {
  title: string
  icon: 'success' | 'error'
  showConfirmButton: boolean
}) => {
  return Swal.fire({
    title,
    icon,
    iconHtml: '',
    showConfirmButton,
    buttonsStyling: false,
    customClass: {
      popup: '',
      confirmButton: 'ant-btn ant-btn-primary ant-btn-lg',
    },
  })
}

function isValidNonEmptyObject(obj: any): boolean {
  // Check if the input is a Record<string, any> and is not an empty object
  if (obj && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length > 0) {
    return true
  }
  return false
}

export const Helper = {
  searchArrayByName,
  checkEmailFormat,
  fCurrency,
  fMoney,
  fPercent,
  fNumber,
  fShortenNumber,
  fData,
  fDate,
  fDateTime,
  fDateTimeSuffix,
  fToNow,
  capitalizeFirstLetter,
  haxToUrlBlob,
  cryptoUtil,
  findMaxValueObjInArray,
  findMinValueObjInArray,
  Swal2Alert,
  fDateTimeUser,
  isValidNonEmptyObject
}
